/* src/components/Resume.css */
.resume {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .resume-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .resume-header h1 {
    margin: 0;
    font-size: 2em;
  }
  
  .resume-header p {
    margin: 5px 0;
  }
  
  .resume-section {
    margin-bottom: 20px;
  }
  
  .resume-section h2 {
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  
  .resume-section h3 {
    margin: 10px 0 5px;
  }
  
  .resume-section p {
    margin: 5px 0;
  }
  
  .resume-section ul {
    list-style-type: disc;
    margin: 5px 0 5px 20px;
    padding: 0;
  }
  
  .filter-controls {
    margin-bottom: 20px;
  }
  /* Ensure consistent styling for the PDF export */
body {
    font-family: Arial, sans-serif;
  }
  
  .resume-section {
    margin: 20px;
    padding: 20px;
  }
  
  .page {
    width: 100%;
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #fff;
  }
  
  .experience-card {
    margin-bottom: 20px;
  }
  
  .company-name {
    font-weight: bold;
  }
  
  .date-range {
    font-style: italic;
  }
  
  .drawer-toggle {
    z-index: 999;
  }
  
  @media print {
    .page {
      break-inside: avoid;
    }
  }