/* src/styles.css */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  header {
    background-color: #6189da;
    padding: 10px 20px;
  }
  
  header nav ul {
    list-style: none;
    padding: 0;
  }
  
  header nav ul li {
    display: inline;
    margin-right: 10px;
  }
  
  header nav ul li a {
    color: white;
    text-decoration: none;
  }
  
  footer {
    background-color: #282c34;
    color: white;
    text-align: center;
    padding: 10px 20px;
    position: fixed;
    width: 100%;
    bottom: 0;
  }

  /* styles.css */

.experience-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .experience-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px; /* Adjust the width as needed */
  }
  
  .experience-card h3 {
    margin-bottom: 10px;
  }
  
  .experience-card ul {
    list-style-type: none;
    padding: 0;
  }
  
  .experience-card li {
    margin-bottom: 10px;
  }
  
  .experience-card p {
    margin: 5px 0;
  }
  .experience-card .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .experience-card .job-titles {
    font-weight: bold;
  }
  
  .experience-card .date-range {
    font-style: italic;
  }

  .checkbox-grid {
    display: flex;
    flex-wrap: wrap;
  }
  
  .checkbox-grid .ant-checkbox-wrapper {
    flex-basis: 50%; /* Each checkbox takes up 50% of the container width */
    margin-bottom: 8px; /* Add some margin between checkboxes */
  }
  
  .filter-section {
    padding: 20px; /* Adjust the value as needed */
  }